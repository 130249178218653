var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between h-screen max-h-screen"
  }, [_c('div', {
    staticClass: "pl-8 pt-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', [_c('ba-base-icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "ba-frc-logo",
      "width": "61"
    }
  })], 1), _vm._m(0)]), _vm._m(1), _c('div', {
    staticClass: "mb-8 pb-8"
  }, [_c('button', {
    staticClass: "bg-warning py-2 border-0 rounded mr-5 font-weight-bold px-5 mb-3",
    attrs: {
      "id": "BA_AMP_LOGIN"
    },
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.AUTH.LOGIN.NAME
        });
      }
    }
  }, [_vm._v(" Login To Reporting Tool ")]), _c('button', {
    staticClass: "py-2 bg-white border border-warning rounded font-weight-bold px-5 mr-5 mb-3",
    attrs: {
      "id": "BA_AMP_SIGN_UP"
    },
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.AUTH.SIGN_UP.NAME
        });
      }
    }
  }, [_vm._v(" Sign Up ")]), _c('button', {
    staticClass: "py-2 bg-black text-white rounded font-weight-bold px-5 mb-3",
    attrs: {
      "id": "BA_AMP_faq"
    },
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.HELP.INDEX.NAME
        });
      }
    }
  }, [_vm._v(" FAQs ")])]), _c('div', {
    staticClass: "p-relative"
  }, [_c('ba-bodadmin-logo', {
    staticStyle: {
      "left": "5rem"
    },
    attrs: {
      "dark": ""
    }
  })], 1)]), _c('div', [_c('b-img-lazy', {
    attrs: {
      "src": require('../../../assets/pngs/reporting_landing.png'),
      "alt": "Auth avatar",
      "width": "790px"
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-lg frc"
  }, [_vm._v(" FINANCIAL REPORTING"), _c('br'), _vm._v(" COUNCIL OF NIGERIA ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-5 mb-8 pb-6 text-lg-4"
  }, [_c('p', {
    staticClass: "text-black font-weight-bold"
  }, [_vm._v(" Take The First "), _c('br'), _vm._v("Step Into "), _c('br'), _c('span', {
    staticClass: "text-warning"
  }, [_vm._v("The Future")])])]);

}]

export { render, staticRenderFns }