
import Vue from "vue";
import { AUTH, HELP } from "../../routes/endpoints";

export default Vue.extend({
    data() {
        return {
            AUTH,
            HELP,
        };
    },
    created() {
        this.$router.push({ name: AUTH.LOGIN.NAME });
    },
    methods: {
        goTo({ name }: { name: string }) {
            this.$router.push({ name });
        },
    },
});
